import { Authority } from '~/src/enums/Authority'
import { useUser } from '~/composables/data/useUser'

interface AuthMeta {
  authority: Authority
}

export default defineNuxtRouteMiddleware(async (to) => {
  const supabaseUser = useSupabaseUser()
  const { user, suspense } = useUser()

  if (supabaseUser.value) {
    await suspense()
  }

  const localePath = useLocalePath()

  const isDifferentRoute = (route: string) => to.path !== localePath(route)

  if (to.path === localePath('reset')) {
    return
  }

  if (!user.value) {
    return navigateTo(localePath('sign-in'))
  }

  const authority: Authority = to.meta.auth ? (to.meta.auth as AuthMeta).authority as Authority : Authority.Completed

  const isSurveyCompleted = user.value && user.value.questionnaire && user.value.currentCampaign

  if (isSurveyCompleted && authority === Authority.Completed) {
    return
  }

  if (!isSurveyCompleted && isDifferentRoute('survey')) {
    return navigateTo(localePath('survey'))
  }
})
